.dashboard {
  padding: 10px;
  border-radius: 10px;
  margin: 40px 100px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.bio-container {
  background-color: #f2f8fc;
  border-radius: 10px;

  .main-intro {
    border-bottom: 2px solid #b0b3b5;
  }

  p {
    color: #898c8e;
    font-size: 18px;
    margin: 0px;
    line-height: 1.25;
    font-weight: 500;
  }
  h4 {
    margin-bottom: 5px;
  }

  h5 {
    margin-bottom: 5px;
  }
  .uid {
    margin-top: 10px;
    font-size: 12px;
    color: #7b7d7f;
    font-weight: 700;
    font-style: italic;
  }
}

.event-icon {
  position: absolute;
  right: -28px;
  width: 55px;
  top: 50%;

  transform: translate(0, -50%);
  height: auto;
}

.whats-new {
  background-color: #00406e;
  border: 5px solid #004f88;
  border-radius: 10px;

  h2 {
    color: white;
  }
}

.start-learning {
  border: 2px solid #ee533c;
  border-radius: 10px;
  p {
    color: #8c95a8;
    font-size: 18px;
    font-weight: 600;
  }
}

.latest-ai {
  border: 2px solid #c8d934;
  border-radius: 10px;
}

.latest-blogs {
  border: 2px solid #f8a64b;
  border-radius: 10px;
}
.latest-events {
  border: 2px solid #00406e;
  border-radius: 10px;
}

.socials {
  border: 2px solid #27c4ee;
  border-radius: 10px;
  .social-vertical-text {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    text-align: center;
    color: #04c4fa;
    font-size: 18px;
    font-weight: 700;
    transform: rotate(180deg);
  }
}

.blog-profile-pic {
  width: 20px !important;
  height: 20px !important;
}

.event {
  border-radius: 15px;
  line-height: 1.4;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 16px;
  width: fit-content;
  font-weight: 600;
  display: table-caption;
  position: relative;
  text-align: center;
}

.event-count {
  position: absolute;
  left: -30px;
  font-size: 50px;
  top: 50%;
  font-weight: 700;

  transform: translate(0, -50%);
}

.arrows:hover {
  fill: #1d3054 !important;
}

.white-arrows:hover {
  fill: white !important;
}

.card-dashboard {
  border-radius: 10px !important;
  width: 245px;
}

.whats-new .card-dashboard {
  width: 100%;
}

.img-user {
  border: 2px solid #6ea6cf;
  width: 120px;
  height: 120px;
  margin-bottom: 0;
}

.event-container {
  margin: 30px 70px !important;
}

.card-img-top-dashboard {
  border-radius: 10px 10px 0 0 !important;
}

.enroll-button {
  padding: 5px 20px;
  margin-left: -15px;
  border-radius: 10px 0px 10px 0px;
  font-size: 12px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50% 50%;
  border: 1px solid #445371;
}
.link-button {
  height: fit-content;
  padding: 6px 10px;
}

.know-more-event {
  border-radius: 10px 0px 0px 0px;
  margin-right: -8px;
  font-size: 12px;
  padding-top: 8px;
}

.know-more {
  border-radius: 10px 0px 9px 0px;
  font-size: 12px;
  width: 100px;
  padding-right: 14px;
  margin-left: 2px;
  padding-top: 8px;
}

.know-more {
  &:hover,
  &:active,
  &:focus {
    background-color: $theme-science-blue !important;
    border-color: $theme-science-blue !important;
    color: white !important;
  }
}

.whats-new .slide {
  width: 100%;
}

@media (min-width: 576px) {
  .card-dashboard {
    min-width: 300px;
  }
  .whats-new .card-dashboard {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .bio-container {
    .main-intro {
      border-right: 2px solid #b0b3b5;
      border-bottom: none;
    }
  }
}

@media (min-width: 1200px) {
  .whats-new .slide {
    width: percentage(1/3);
  }
}

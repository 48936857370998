@media only screen and (min-width: 768px) {
  .header {
    .header-links-container {
      justify-content: space-between;
      flex-direction: row;
    }
    .header-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .header-brand {
      margin-bottom: 0;
    }
  }
}

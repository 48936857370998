// variables
// all colors naming from: http://chir.ag/projects/name-that-color/
$theme-stone: #1a2b4b;
$hover-theme-stone: #16243e;
$theme-cello: #223964;
$hover-theme-cello: #1e3257;
$theme-link-water: #e9f0fa;
$theme-wattle: #c9d651;
$theme-blackcurrant: #382c45;
$theme-jaffa: #eb983e;
$theme-mariner: #2c71bf;
$theme-mischka: #d2d6dd;
$theme-flame-pea: #de5b33;
$hover-theme-flame-pea: #ce4a21;
$theme-cadet-blue: #abb2c0;
$theme-athens-gray: #e8eaed;
$theme-blackcurrant-50: rgba(56, 44, 69, 0.5);
$theme-white-10: rgba(255, 255, 255, 0.1);
$theme-white-50: rgba(255, 255, 255, 0.5);
$theme-science-blue: #0062cc;
$theme-chambray: #325280;
$theme-oslo-gray: #808c95;
$theme-black-12: rgba(0, 0, 0, 0.125);
$theme-black: #000;

@import "../node_modules/react-modal-video/scss/modal-video.scss";

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  /*width: 8px;*/
  /*border-radius: 30px;*/
}

/* Track */
*::-webkit-scrollbar-track {
  /*background: rgba(74, 102, 160, 0.6);*/
}

/* Handle */
*::-webkit-scrollbar-thumb {
  /*background: #0B528A;*/
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  /*background: #3b5998;*/
}

code {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: #162b4d;
}

.search-input {
  border-radius: 29px;
  border-color: #162b4d;
  margin-right: 10px;
  padding: 8px 20px;
  border-width: 1px;
  outline: none;
}

*,
*:focus,
*:hover,
*:active {
  outline: none !important;
}

.elem-float-right {
  float: right;
}

.pagination-container {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-container ul {
  padding-left: 0px;
}

.pagination {
  min-width: 70rem;
}

.pagination li {
  background: #fff;
  color: #000 !important;
  border: 1px solid #717171;
  border-radius: 50%;
  font-size: 16px;
  padding: 0px;
  height: 30px;
  cursor: pointer;
  width: 30px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.pagination li a {
  color: #000;
}

.pagination li.active,
.pagination li:hover {
  background-color: #162b4d !important;
  color: #fff !important;
  border-color: #162b4d !important;
}

.pagination li.active a,
.pagination li:hover a {
  color: #fff;
}

.pagination li.disabled,
.pagination li.disabled:hover {
  background-color: #4d4e4f !important;
  color: #fff !important;
  cursor: no-drop !important;
  border-color: #162b4d !important;
}

.pagination {
  padding-left: 0px !important;
  min-width: 90vw !important;
  align-items: center;
  text-align: center;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.react-pdf__Page__canvas{
  width: 100% !important;
  height: auto !important;
}

.blog-container {
  padding: 30px;
  margin-bottom: 80px !important;
  border-radius: 10px;
  padding-left: 41px;
}

.h4-margin {
  margin: 20px;
}

.card-p {
  inset-block: initial;
}

.h5-author {
  margin-bottom: 7px;
}

.single-blog-card {
  border-radius: 20px;
  padding-top: 30px;
}

.vert-item {
  width: 33.33%
}

.single-blog-card img {
  height: 190px;
  width: 100%;
}

.single-blog-card.big img {
  height: 400px;
}

.single-event-card {
  margin-bottom: 30px;
}

.mar-b-20 {
  margin-bottom: 40px;
}

.custom-pagination-nav {
  background-color: #f3f3f3;
}

.card-text {
  overflow: hidden;
}

/**************************************
events page css
**************************************/
.subtitle {
  font-size: 18px;
  line-height: 22px;
  color: #4d4e4f;
  font-weight: 400;
  cursor: pointer;
}

.gray-hr {
  border-color: #4d4e4f;
  border-width: 3px;
}

.border-bottom-gray {
  margin-bottom: 25px;
  border-bottom: 1px solid #4d4e4f;
}

.no-padding {
  padding: 0px;
}

.margin-h-15 {
  margin: 0px 15px;
}

.padding-h-15 {
  padding: 0 15px;
}

.border-right-gray {
  border-right: 1px solid #4d4e4f;
}

.card-content {
  padding: 15px;
}

.event-title {
  font-size: 20px;
  color: #162b4d;
  line-height: 1.4;
  font-weight: 600;
}

.no-mb {
  margin-bottom: 3px !important;
}

.card-img-top {
  cursor: pointer;
}

.container-table100 {
  margin-bottom: 100px;
}

.event-author {
  background-color: #f3f3f3;
  text-align: left;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;
  font-weight: 600;
  margin: 0px -15px -15px -15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.event-author p {
  margin-bottom: 0 !important;
}

.event-location {
  padding: 16px 0px;
}

.loc-icon {
  margin-right: 10px;
  width: 22px !important;
  height: 22px !important;
  display: inline;
}

.event-location p {
  display: inline;
  text-transform: capitalize;
}

.event-desc {
  min-height: 105px;
}

.event-tags {
  margin-bottom: 12px;
}

.badge-pill {
  margin-right: 12px;
}

/***********************************
sign up form css
***********************************/
.form-control {
  width: 100%;
}

.login-signup-form .form-group label {
  font-size: 1.1rem;
}

.form-control {
  font-size: 1.1rem;
}

.pt-30 {
  padding-top: 30px;
}

.ptb-30 {
  padding: 50px 0;
}

.full-screen {
  align-items: end !important;
}

.card-title {
  text-align: left;
  min-height: 50px;
}

.blog-cover {
  margin-bottom: 25px;
  max-height: 400px;
  text-align: center;
  width: 100%;
}

.ptb-10 {
  padding: 15px 0px;
}

.pb-10 {
  padding-bottom: 15px;
}

.mt-30 {
  margin-top: 50px !important;
}

.wrap-table100 {
  border: 1px solid #000;
}

.cell.link {
  cursor: pointer;
}

.row.header {
  background: #162b4d !important;
}

.row .cell {
  font-size: 18px !important;
}

.forum-post {
  padding: 35px 0px;
  width: 100%;
  text-align: left !important;
}

.event-title.small {
  font-size: 14px;
  margin-bottom: 20px !important;
}

.forum-comment-card {
  background-color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
}

/********************************************
dashboard css
********************************************/
.img-user {
  width: 140px;
  margin-bottom: 10px;
}

.link-icon {
  display: inline;
  margin-right: 8px;
  width: 15px !important;
  height: 15px !important;
}

a.gray {
  color: #9a9a9a;
}

.link-gray {
  color: #4d4e4f;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}

.link-gray img {
  filter: grayscale(100%);
  border-radius: 0px !important;
}

.no-padding {
  padding: 0px;
}

.user-bio {
  color: #4d4e4f;
}

.progress {
  border: 3px dashed #162b4d;
  border-radius: 50%;
  min-height: 90px;
  min-width: 90px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #162b4d;
  display: inline-block;
  padding: 24px 5px;
  margin-right: 20px;
}

.progress p {
  width: 100%;
  color: #162b4d;
}

.gray-separator {
  border-top-color: #4d4e4f;
  margin: 25px 0px;
  width: 100%;
  border-top: 2px solid #4d4e4f !important;
}

.learning-white-card-dashboard {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 25px 25px 25px 40px;
}

.image-container {
  border-radius: 15px;
  border: 2px solid #162b4d;
}

.learning-hero-img {
  border-radius: 15px;
  width: 100%;
  height: auto;
}

.display-none {
  display: none;
}

.lesson-desc {
  color: #162b4d;
  line-height: 1.4;
  min-height: 200px;
}

.course-page-desc {
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.8;
  font-weight: 500;
}

.light {
  font-weight: 400 !important;
}

.horizontal-scroll {
  display: flex;
  width: 100%;
  /* overflow-x: auto; */
}

.single-news-card .card-content .event-desc {
  min-height: 105px;
}

.news-desc {
  min-height: 105px;
}

.news-title {
  min-height: 56px;
}

.mar-b-30 {
  margin-bottom: 30px;
}

.next-arrow-scroll {
  position: absolute;
  top: 50%;
  right: 18px;
  background-color: #f3f3f3;
  padding: 6px 12px;
  border-radius: 50%;
  margin-top: -26px;
}

.scroll-icon {
  width: 40px;
  height: 40px;
  margin-top: 6px;
  cursor: pointer;
}

.video-container {
  margin: 30px 0px;
  border-radius: 20px;
}

.inner-link-container {
  background-color: #162b4d;
  border-radius: 20px;
  padding: 15px 0px;
  color: #fff !important;
}

.border-bottom-white {
  border-bottom: 1px solid white;
}

.white {
  color: #fff !important;
}

.mar-l-15 {
  margin-left: 15px;
}

.mar-b-15 {
  margin-bottom: 15px;
}

.pad-b-15 {
  padding-bottom: 15px;
}

.multiple-links {
  padding: 15px;
}

.single-link {
  margin: 0px 15px 15px 15px;
}

.link-title {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 3px !important;
}

.link-desc {
  font-size: 12px;
  line-height: 14px;
}

.pad-t-15 {
  padding-top: 15px;
}

.next-arrow {
  float: right;
  width: 30px;
  margin-right: 30px;
  margin-top: 5px;
  cursor: pointer;
}

.f-right {
  float: right;
  margin: 30px 30px 0px 0px;
}

.course-lets-img {
  height: 270px;
  width: 100%;
}

.start-fresh-btn {
  display: inline;
  padding: 13px 8px;
  font-size: 18px;
  line-height: 1.4;
  border-radius: 10px;
  background-color: transparent;
  margin-left: 20px;
  color: #162b4d;
  box-shadow: 1px 1px 1px 1px #4d4e4f;
}

.start-fresh-btn:hover,
.start-fresh-btn:focus,
.start-fresh-btn:active {
  box-shadow: 3px 3px 3px 3px #4d4e4f;
}

.lesson-desc.without-limit {
  min-height: 70px;
}

.blue-btn {
  background-color: #162b4d;
  padding: 8px 20px;
  color: #fff;
  border: 1px solid #162b4d;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.8;
}

.blue-btn:hover,
.blue-btn:focus,
.blue-btn:active {
  background-color: transparent;
}

.max-width-350 {
  max-width: 450px;
}

.max-width-600 {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 25px;
}

/* Profile CSS */
.profile-container {
  width: 70px;
  height: 70px;
  /* border: 1px solid black; */
  margin-right: 100px;
}

.profile-container img {
  width: 100%;
  height: 100%;
  line-height: 0px;
}

.counter {
  border: 1px solid #fff;
  border-radius: 10px;
  width: 150px;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: #162b4d;
}

.blog-container {
  padding: 30px;

  margin: 40px 100px;
  margin-bottom: 80px !important;
  border-radius: 10px;
  padding-left: 41px;
}

.single-blog-card {
  border-radius: 20px;
}

.single-blog-card img {
  height: 190px;
  width: 100%;
}

.hero-img-blog {
  height: 320px !important;
}

.custom-pagination-nav {
  background-color: #f3f3f3;
}

.card-text {
  overflow: hidden;
}

/**************************************
events page css
**************************************/
.subtitle {
  font-size: 18px;
  line-height: 22px;
  color: #4d4e4f;
  font-weight: 400;
  cursor: pointer;
}

.gray-hr {
  border-color: #4d4e4f;
  border-width: 3px;
}

.border-bottom-gray {
  margin-bottom: 25px;
  border-bottom: 1px solid #4d4e4f;
}

.no-l-padding {
  padding-left: 0px;
}

.no-r-padding {
  padding-right: 0px;
}

.no-padding {
  padding: 0px;
}

.margin-h-15 {
  margin: 0px 15px;
}

.padding-h-15 {
  padding: 0 15px;
}

.border-right-gray {
  border-right: 1px solid #4d4e4f;
}

.card-content {
  padding: 15px;
}

.event-title {
  font-size: 20px;
  color: #162b4d;
  line-height: 1.4;
  font-weight: 600;
}

.no-mb {
  margin-bottom: 3px !important;
}

.card-img-top {
  cursor: pointer;
}

.container-table100 {
  margin-bottom: 100px;
}

.event-author {
  background-color: #f3f3f3;
  text-align: left;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;
  font-weight: 600;
  margin: 0px -15px -15px -15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.event-author p {
  margin-bottom: 0 !important;
}

.event-location {
  padding: 16px 0px;
}

.loc-icon {
  margin-right: 10px;
  width: 22px !important;
  height: 22px !important;
  display: inline;
}

.event-location p {
  display: inline;
  text-transform: capitalize;
}

.event-tags {
  margin-bottom: 12px;
}

.badge-pill {
  margin-right: 12px;
}

/***********************************
sign up form css
***********************************/
.form-control {
  width: 100%;
}

.login-signup-form .form-group label {
  font-size: 1.1rem;
}

.form-control {
  font-size: 1.1rem;
}

.ptb-30 {
  padding: 50px 0;
}

.full-screen {
  align-items: end !important;
}

.card-title {
  text-align: left;
  min-height: 70px;
}

.blog-cover {
  margin-bottom: 25px;
  max-height: 400px;
  text-align: center;
}

.ptb-10 {
  padding: 15px 0px;
}

.pb-10 {
  padding-bottom: 15px;
}

.mt-30 {
  margin-top: 50px !important;
}

.wrap-table100 {
  border: 1px solid #000;
}

.cell.link {
  cursor: pointer;
}

.row.header {
  background: #162b4d !important;
}

.row .cell {
  font-size: 18px !important;
}

.forum-post {
  padding: 35px 0px;
  width: 100%;
  text-align: left !important;
}

.event-title.small {
  font-size: 14px;
  margin-bottom: 20px !important;
}

.forum-comment-card {
  background-color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
}

/********************************************
dashboard css
********************************************/
.img-user {
  width: 140px;
  margin-bottom: 10px;
}

.link-icon {
  display: inline;
  margin-right: 8px;
  width: 15px !important;
  height: 15px !important;
}

.profile-icon {
  display: inline;
  margin-right: 8px;
  width: 25px !important;
  height: 25px !important;
  margin-bottom: -8px;
}

a.gray {
  color: #9a9a9a;
}

.link-gray {
  color: #4d4e4f;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}

.link-gray img {
  filter: grayscale(100%);
  border-radius: 0px !important;
}

.no-padding {
  padding: 0px;
}

.user-bio {
  color: #4d4e4f;
}

.progress {
  border: 3px dashed #162b4d;
  border-radius: 50%;
  min-height: 90px;
  min-width: 90px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #162b4d;
  display: inline-block;
  padding: 24px 5px;
  margin-right: 20px;
}

.progress p {
  width: 100%;
  color: #162b4d;
}

.gray-separator {
  border-top-color: #4d4e4f;
  margin: 25px 0px;
  width: 100%;
  border-top: 2px solid #4d4e4f !important;
}

.learning-white-card-dashboard {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 25px 25px 25px 40px;
}

.image-container {
  border-radius: 15px;
  border: 2px solid #162b4d;
}

.learning-hero-img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-height: 300px;
}

.lesson-desc {
  color: #162b4d;
  line-height: 1.4;
  min-height: 200px;
}

.course-page-desc {
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.8;
  font-weight: 500;
}

.light {
  font-weight: 400 !important;
}

.single-news-card {
  max-width: 320px;
  min-width: 320px;
  display: inline-flex;
  margin-right: 25px;
}

.mar-b-30 {
  margin-bottom: 30px;
}

.video-container {
  margin: 60px 0px;
  border-radius: 20px;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
}

.inner-link-container {
  background-color: #162b4d;
  border-radius: 20px;
  padding: 15px 0px;
  color: #fff !important;
}

.border-bottom-white {
  border-bottom: 1px solid white;
}

.white {
  color: #fff !important;
}

.mar-l-15 {
  margin-left: 15px;
}

.mar-b-15 {
  margin-bottom: 15px;
}

.pad-b-15 {
  padding-bottom: 15px;
}

.multiple-links {
  padding: 15px;
}

.single-link {
  margin: 0px 15px 15px 15px;
}

.link-title {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 3px !important;
}

.link-desc {
  font-size: 12px;
  line-height: 14px;
}

.pad-t-15 {
  padding-top: 15px;
}

.next-arrow {
  float: right;
  width: 30px;
  margin-right: 30px;
  margin-top: 5px;
  cursor: pointer;
}

.f-right {
  float: right;
  margin: 30px 30px 0px 0px;
}

.course-lets-img {
  width: 90%;
  margin: auto;
}

.start-fresh-btn {
  display: inline;
  padding: 13px 8px;
  font-size: 18px;
  line-height: 1.4;
  border-radius: 10px;
  background-color: transparent;
  margin-left: 20px;
  color: #162b4d;
  box-shadow: 1px 1px 1px 1px #4d4e4f;
}

.start-fresh-btn:hover,
.start-fresh-btn:focus,
.start-fresh-btn:active {
  box-shadow: 3px 3px 3px 3px #4d4e4f;
}

.lesson-desc.without-limit {
  min-height: 70px;
}

.blue-btn {
  background-color: #162b4d;
  padding: 8px 20px;
  color: #fff;
  border: 1px solid #162b4d;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.8;
}

.blue-btn:hover,
.blue-btn:focus,
.blue-btn:active {
  background-color: transparent;
}

.max-width-350 {
  max-width: 450px;
}

.max-width-600 {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 25px;
}

/* Profile CSS */
.profile-container {
  width: 100px;
  height: 100px;
  /* border: 1px solid black; */
  margin-right: 100px;
}

.profile-container img {
  width: 100%;
  height: 100%;
  line-height: 0px;
}

/* user Profile */
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.dropdown img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #162b4d;
  min-width: 130px;
  right: 10px;
  z-index: 10000;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.border-line {
  width: 90%;
}

.dropdown-content a {
  color: #162b4d;
  padding: 10px 16px;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.dropdown-content a:hover {
  float: right;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: right;
}

.btn-blue-round {
  border-radius: 6px;
  background: #162b4d;
  color: #fff;
  padding: 10px 50px 10px 50px;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.mrb-0 {
  margin-bottom: 0px !important;
}

.course-card-lets-learn {
  background-color: #fff;
  width: 100%;
  padding: 25px;
  border-top: 1px solid #4d4e4f;
  margin-bottom: 35px;
}

.border-bottom-black {
  padding: 15px;
  padding-bottom: 0px;
  border-bottom: 1px solid #000;
}

.mar-t-15 {
  margin-top: 15px;
}

.module-title {
  color: #4d4e4f;
  font-size: 22px;
  font-weight: bold;
  margin-left: 8px;
  border-bottom: 1px solid #4d4e4f;
  padding-bottom: 12px;
  margin-bottom: 16px;
  line-height: 1.7;
  width: 80%;
  text-align: left;
}

.topic-title {
  color: #4d4e4f;
  font-size: 22px;
  font-weight: 400;
  margin-left: 8px;
  border-bottom: 1px solid #4d4e4f;
  padding-bottom: 8px;
  margin-bottom: 8px;
  line-height: 1.7;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.topic-icon {
  display: inline;
  width: 30px;
  margin-left: 30px;
  margin-right: 15px;
  margin-bottom: -5px;
}

.no-padding {
  padding: 0px;
}

.padding-25 {
  padding: 25px;
}

.bdr-20 {
  border-radius: 20px;
}

.display-inline {
  display: inline;
}

.navbar {
  padding: 0.5rem 0;
}

.main-menu {
  padding-right: 15px;
}

.profile-img {
  border: 2px solid #fff;
  background-color: #fff;
  cursor: pointer;
}

.ptb-100 {
  padding: 50px 0px;
}

.video-play-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.video-play-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  transition: ease-in;
  transition-duration: 0.5s;
}

.video-play-btn img {
  width: 80px;
  margin-top: 170px;
  height: 80px;
  cursor: pointer;
}

.notification-message .title {
  color: #fff;
}

.return-icon {
  display: inline-block;
  width: 15px;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
}

.return-label {
  margin-top: -4px;
  color: #162b4d;
  display: inline;
  cursor: pointer;
}

.loc-event-container {
  margin-bottom: 20px;
}

.loc-event-container .return-label {
  color: #000;
  text-transform: capitalize;
}

/** video modal css */
.modal-video {
  background: rgba(0, 0, 0, 0.75);
}

#timeline-Widget .timeline-Widget {
  background: rgba(0, 0, 0, 0.75);
}

/** new css */
.left-blue-border {
  border-left: 2px solid #162b4d;
  padding-left: 25px;
}

.lead {
  font-size: 12px;
  line-height: 1.8;
}

.mb-50 {
  margin-bottom: 50px;
}

.icon-card {
  background-color: #fff;
  font-size: 14px;
  padding: 20px 30px;
  text-align: center;
  border: 1px solid #344258;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  max-width: 280px;
}

.icon-card img {
  width: auto;
  height: 100px;
  margin-bottom: 18px;
}

.icon-card-title {
  color: #344258;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
}

.icon-card-desc {
  color: #999;
  margin-bottom: 8px;
}

.fw-400 {
  font-weight: 400;
}

.landing-event-table .wrap-table100 {
  border: 0px !important;
  border-radius: 0px;
  width: 100%;
}

.landing-event-table .row.header {
  background-color: #162b4d !important;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
  text-align: center;
  background-color: #162b4d !important;
  border-color: #344258;
  border-radius: 0px !important;
}

.landing-event-table .row.header .cell {
  color: #fff !important;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 500;
  padding: 8px 20px;
  text-align: center;
  font-weight: bold;
  border-radius: 0px !important;
}

.landing-event-table .row.table {
  background: #fff;
  font-weight: 400;
}

.landing-event-table .row .cell {
  font-weight: 400;
}

.mt-20 {
  margin-top: 20px;
}

.footer .text-white {
  font-weight: 400;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 12px;
}


label {
  color: #000;
}

.parent {
  width: 10%;
  overflow: hidden;
  margin: 20px;
  padding: 1rem;
}

.slider-container {
  /* if you want to have a scrollbar, you can add overflow here */
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 30px 0px 60px 0px;
  /* scroll-behavior: smooth; */
}

.extra-mar-slider .slider-container {
  margin-top: 50px;
  margin-bottom: 0px;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-slider {
  position: absolute;
  border: none;
  box-shadow: 0 0 6.1px 0.9px grey;
  padding: 4px 0 0 1px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #162b4d;
  text-align: center;
  color: white;
  z-index: 100;
  border-radius: 50%;
  margin: 20px;
}

.btn-slider svg {
  height: 20px;
}

.prev {
  left: -2.5rem;
  margin-right: 20px;
}

.next {
  right: -2.6rem;
  margin-left: 20px;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.child {
  padding: 5px 20px;
  background: #59eb97;
  text-align: center;
  width: auto;
  margin-right: 10px;
  border-radius: 21px;
}

.blogs-cover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.blogs-footer {
  padding-right: 10px;
  padding-bottom: 0px;
}

.card-footer.blog-footer-view {
  padding: 5px;
}

.blogs-footer img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.blog-footer-img-container {
  display: inline-block;
  padding: 10px 8px;
  margin-right: 4px;
}

.blog-author-footer {
  display: inline-block;
  margin-top: -4px;
  margin-bottom: 0px;
  vertical-align: super;
}

.blogs-footer-text {
  margin-top: -30px;
}

.single-blog-content1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.single-blog-card {
  border-radius: 20px;
}

.social-media {
  padding-left: 10px;
}

.single-blog-content2 {
  padding-left: 25px;
  padding-right: 60px;
}

.blogs-tag {
  display: flex;
  flex-direction: row;
  z-index: 2;
  top: 5px;
  right: 0;
  position: absolute;
}

.category {
  display: flex;
  flex-direction: row;
}

.mar-r-8 {
  margin-right: 8px;
}

.post-details-content1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-new-post {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* margin-top: -10px; */
}

tr {
  border-left: 7px solid #162b4d;
}

select {
  background-color: #162b4d;
  border: none;
  color: #f3f3f3;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  width: 80px;
  margin-left: 10px;
}

.all-select-categories {
  background-color: #162b4d;
  width: 100px;
  height: 30px;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 6px;
}

.select option {
  border: none;
  color: white;
  width: 100px;
  height: 40px;
  padding: 20px;
  border: 1px solid white;
}

.category-btn {
  background-color: #162b4d;
  width: 100px;
  height: 30px;
  margin-top: 20px;
  border: none;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #162b4d;
}

.category-btn:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #162b4d;
}

.category-heading {
  margin-left: 20px;
  margin-top: 10px;
}

.category-table {
  /* width: 10%; */
}

tbody tr {
  cursor: pointer;
}

.post-details-container {
  padding-top: 30px;
}

.table-header-category {
  padding-bottom: 20px;
}

.post-heading {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.author-image p {
  text-align: center;
  margin-top: -30px;
  margin-top: -40px;
  margin-left: 40px;
  color: #162b4d;
}

.author-image img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.post-tags {
  display: flex;
  padding-left: 0px;
}

.post-tags span {
  display: inline-flex;
  margin-right: 10px;
}

.new-post {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.new-post button {
  height: 40px;
}

.modal-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  z-index: 200;
  cursor: pointer;
}

.post-new-title {
  padding-left: 44px;
}

.post-new-title .input {
  padding-right: 100px;
}

.return-back {
  padding-left: 50px;
  padding-bottom: 20px;
}

.mar-b-25 {
  margin-bottom: 25px;
}

.event-icon {
  width: 18px;
  /* height: ; */
}

.mar-b-10 {
  margin-bottom: 10px;
}

.width {
  width: 635px;
}

.no-l-mar,
.dropdown-categs select {
  margin-left: 0px;
}

.blogs-heading {
  margin-top: -70px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-bottom: -30px;
}

.go-back-mar {
  margin-bottom: 12px;
}

.data-block-caption a {
  color: #162b4d;
}

.data-block-caption img {
  max-width: 500px;
  margin: 0 auto;
}

.primary {
  color: #162b4d;
}

.sub-heading {
  color: #717171;
  font-size: 16px;
  line-height: 1.8;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.our-blog-section {
  overflow-x: hidden;
  padding-top: 160px;
}

.iaiif-registration-page {
  overflow-x: hidden;
  padding-top: 212px;
}

.festival-section {
  overflow-x: hidden;
  padding-top: 115px;
}

.card-footer {
  padding: 0px 8px;
}

.card-footer .link-gray {
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

#profileSubmit {
  margin: 0 auto;
}

.dropdown-item {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 576px) {
  .learning-hero-img {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  .image-container {
    height: 300px;
  }

  .iaiif-registration-page {
    padding-top: 205px;
  }
}

@media (max-width: 720px) {
  .blog-container {
    margin: 20px;
  }
}

@media (min-width: 768px) {
  .single-blog-container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .festival-section {
    padding-top: 80px;
  }
  .iaiif-registration-page {
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .festival-section {
    padding-top: 120px;
  }
  .lead {
    font-size: 16px;
  }
  .iaiif-registration-page {
    padding-top: 142px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}
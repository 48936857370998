// utilities
.d-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.flex-center {
  @extend .d-flex;
  align-items: center;
  justify-content: center;
}
.p-0 {
  padding: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.d-none {
  display: none;
}
.c-not-allowed {
  cursor: not-allowed;
}
.text-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.h-40 {
  height: 40px;
}

.h-60p {
  height: 60px !important;
}

.rounded-top-10 {
  border-radius: 10px 10px 0 0;
}

.rounded-10 {
  border-radius: 10px !important;
}

.c-pointer {
  cursor: pointer;
}

.pt-265p {
  padding-top: 265px;
}

.w-40 {
  width: 40px;
}
.w-250 {
  width: 250px;
}

.h-40 {
  height: 40px;
}

.border-bottom-3 {
  border-bottom: 3px solid;
}

.border-oslo-gray {
  border-color: $theme-oslo-gray;
}

.text-chambray {
  color: $theme-chambray;
}

.border-black-12 {
  border-color: $theme-black-12 !important;
}

.border-theme-science-blue {
  border-color: $theme-science-blue !important;
}

.h-48p {
  height: 48% !important;
}

.border-4 {
  border: 4px solid;
}

.text-black {
  color: $theme-black;
}

.fz-30 {
  font-size: 30px !important;
}

@import "md";
@import "lg";
@import "xl";
